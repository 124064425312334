import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { MAX_LIMIT, paramsByIds } from '@/api/api'
import { PaymentInvoice } from '@/models/payments/PaymentInvoice'

export class PaymentInvoiceGet {
  static async listWithProforma(params: ObjectParams): Promise<List<PaymentInvoice>> {
    return PaymentInvoiceGet.list({
      ...params,
      enableProforma: true,
    })
  }

  static async list(params: ObjectParams): Promise<List<PaymentInvoice>> {
    const list: List<PaymentInvoice> = { count: 0, items: [] }
    try {
      const l = await paymentsApi.invoice.list(params)
      list.count = l.count
      list.total = l.total
      list.items = l.items.map((v) => new PaymentInvoice(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return list
  }

  static async count(params: ObjectParams): Promise<number> {
    let count = 0
    try {
      count = await paymentsApi.invoice.count(params)
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return count
  }

  static async byID(id: number): Promise<PaymentInvoice> {
    let item = new PaymentInvoice()
    try {
      item = new PaymentInvoice(await paymentsApi.invoice.getByID(id))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return item
  }

  static async byIDs(ids: number[]): Promise<PaymentInvoice[]> {
    let items: PaymentInvoice[] = []
    try {
      items = (await paymentsApi.invoice.getByIDs(ids)).map((v) => new PaymentInvoice(v))
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return items
  }

  static async mapByIDs(ids: number[]): Promise<Map<number, PaymentInvoice>> {
    const persons = await PaymentInvoiceGet.byIDs(ids)

    const pMap = new Map<number, PaymentInvoice>()
    persons.forEach((v) => pMap.set(v.id, new PaymentInvoice(v)))

    return pMap
  }

  static async byPaymentIDs(ids: number[]): Promise<PaymentInvoice[]> {
    if (!ids || !ids.length) return []

    return (
      await this.list({
        ...paramsByIds(ids, 'paymentIDs'),
        limit: MAX_LIMIT,
      })
    ).items
  }

  static async mapByPaymentIDs(ids: number[]): Promise<Map<number, PaymentInvoice[]>> {
    const pMap = new Map<number, PaymentInvoice[]>()
    const obj: { [key: string]: PaymentInvoice[] } = {}
    try {
      const res = await PaymentInvoiceGet.byPaymentIDs(ids)
      res.forEach((v) => {
        if (v.paymentID) {
          v.paymentID && obj[v.paymentID]
            ? obj[v.paymentID].push(new PaymentInvoice(v))
            : (obj[v.paymentID] = [new PaymentInvoice(v)])
        }
      })

      for (const key in obj) {
        pMap.set(Number(key), obj[key])
      }
    } catch (e) {
      await MainStore.addError(e as Error)
    }
    return pMap
  }
}
